import { h } from 'preact';
import { Router } from 'preact-router';

// Code-splitting is automated for `routes` directory
import Home from '../routes/home';
import Games from '../routes/games';
import Quiz from '../routes/quiz';
import Datenschutz from '../routes/datenschutz';

const App = () => (
	<div id="app">		
		<Router>
			<Home path="/" />
			<Games path="/games" />
			<Quiz path="/quiz/:saison?/:url?" />
			<Datenschutz path="/datenschutz" />
		</Router>
	</div>
)

export default App;
